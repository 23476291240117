import moment from 'moment-timezone'

moment.locale("es");
moment.tz.setDefault('America/Argentina/Buenos_Aires');

export const isoDateToMonth = (isoDate: string, monthName?: boolean) => {
  return isoDate ? moment(isoDate).format(monthName ? "MMMM" : "M") : "N/A";
};

export const isoDateToDay = (isoDate: string, dayName?: boolean) => {
  return isoDate ? moment(isoDate).format(dayName ? "dddd" : "D") : "N/A";
};

export const isoDateToSimpleDate = (isoDate: string): string => {
  return moment(isoDate).format("L");
};
export const isoDateToHour = (isoDate: string): string => {
  return moment(isoDate).format("LT a");
};
export const eventDate = (date: Date): string => {
  return moment(date).format("DD") + " de " + moment(date).format("MMMM");
};

export const isoDateToTextDate = (date: Date): string => {
  return (
    moment(date).format("dddd") +
    " " +
    moment(date).format("DD") +
    " de " +
    moment(date).format("MMMM") +
    " a las " +
    moment(date).format("H:mm") +
    " horas"
  );
};

export const idoDateToDateAndHours = (isoDate: string): string => {
  return moment(isoDate).format("DD/MM/YYYY H:mm");
};

export const userYear = (date: Date): number => {
  const nacimiento = moment(date);
  const hoy = moment();
  const anios = hoy.diff(nacimiento, "years");

  return anios;
};

export const eventDateTime = (date: Date): string => {
  return moment(date).format("LT") + " HS";
};

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
